.modal-dark .modal-content {
    background-color: #2d2a4a; /* Dark purple */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}


.modal-dark .modal-header {
    background-color: #3a3555; /* Slightly lighter purple */
    border-bottom: none;
    color: #ffffff;
    font-weight: bold;
}

.modal-dark .btn-primary {
    background-color: #5e4bff; /* Vibrant blue */
    border: none;
    color: #ffffff;
}

.modal-dark .btn-secondary {
    background-color: #444; /* Neutral dark gray */
    border: none;
    color: #ffffff;
}

.modal-dark .btn-primary:hover {
    background-color: #4a39cc; /* Darker shade for hover effect */
}

.modal.fade .modal-dialog {
    transform: translateY(-50px);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.modal.fade.show .modal-dialog {
    transform: translateY(0);
    opacity: 1;
}

