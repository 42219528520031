.navbar {
  transition: background-color 0.3s ease-in-out, --bs-bg-opacity 0.3s ease-in-out;
}

.main-token-card {
  background-color: #230B5E;
  color: #fff;
}

.main-token-card .chain-icon {
  position:absolute;
  right: 15px;
}


.card .img-fluid {
  border-radius: 12px;
}

.truncate-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.sortDropdown .dropdown-item {
  color:white;
  background: none;
}

.sortDropdown .btn, .sortDropdown .btn:hover, .sortDropdown .btn:active, .sortDropdown .btn:focus {
  background: none;
  border: none;
  width: 150px;
  height: 100%;
  max-width: 150px;
}