.text-white {
    color: #fff;
}

.text-secondary {
    color: #aaa;
}

.text-warning {
    color: #FFCC32;
}

.text-info {
    color: #5DA9E9;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.mainTable .card-body {
    padding: 4px 0px !important;
}