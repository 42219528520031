@font-face {
    font-family: 'Geist';
    src: url('/public/fonts/Geist-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Geist';
    src: url('/public/fonts/Geist-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

body {
    font-family: 'Geist', sans-serif !important;
    background-color: #130048;
}

a {
    text-decoration: none;
}

table {
    background-color: transparent;
    border: none !important;
    margin-bottom: 0px !important;
}

thead, tbody, tr {
    border: none !important;
}

table > thead > tr > * {
    background-color: rgba(0,0,0,0.3) !important;
    --bs-table-bg-state: rgba(0,0,0,0.3) !important;
    padding: 12px !important;
    border: none !important;
}

table > tbody > tr > * {
    padding: 12px !important;
    border: none !important;
}

table > tbody > tr:nth-of-type(odd) > * {
    background-color: rgba(0,0,0,0.3) !important;
    --bs-table-bg-state: rgba(0,0,0,0.3) !important;
}

table > tbody > tr:nth-of-type(even) > * {
    background-color: rgba(0,0,0,0.0) !important;
    --bs-table-bg-state: rgba(0,0,0,0.0) !important;
}

.container-fluid {
    max-width: 1600px;
}

#navbar {
    --bs-bg-opacity: 1;
    background-color: rgba(49, 17, 120, var(--bs-bg-opacity)) !important;
    transition: background-color 0.3s ease-in-out, --bs-bg-opacity 0.3s ease-in-out;
}

@media (min-width: 768px) {
    #navbar .nav-item {
        margin-left: 40px;
    }
}

@media (max-width: 768px) {
    .banner {
        margin-top: 60px;
    }
}

#navbar .nav-link {
    color: #fff;
    margin-right: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.pink-border {
    background: linear-gradient(to bottom right, #7F5AF6, #B080F1);
    padding: 3px;
    border-radius: 16px;
}

.gold-border {
    background: linear-gradient(to bottom right, #FFBA35, #FF753A);
    padding: 3px;
    border-radius: 16px;
}

.purple-border {
    background: linear-gradient(to bottom right, #5a28ff, #9f62f3);
    padding: 3px;
    border-radius: 16px;
}

.white-border {
    background-color: #E5D2FF !important;
    padding: 3px;
    border-radius: 16px;
}

.gold-to-red-border {
    padding: 3px;
    background: linear-gradient(to bottom right, #FFCC32, #FF5121);
    border-radius: 16px;
}

.white-text {
    color: #fff;
}

.white-muted-text {
    color: rgba(255,255,255,0.6);
}

.yellow-text {
    color: #FFBA35;
}

.uppercase {
    text-transform: uppercase;
}

.orange-text {
    color: #FF753A;
}

.orange-icon {
    color: #fff;
    padding: 6px;
    background-color: #FF753A;
    border-radius: 50%;
}

.green-text {
    color: #55FFB7
}

.btn-primary,
.btn-primary:focus {
    background-color: #825AF2;
    border-radius: 12px;
    border: 3px solid #B78BF4;
    padding: 8px 20px;
    text-transform: capitalize;
    font-weight: bold;
    color: #fff;
}

.btn-primary:hover,
.btn-primary:active {
    background-color: #B78BF4;
    border: 3px solid #B78BF4;
}

.btn-primary:disabled {
    background-color: rgba(130, 90, 242, 0.5);
    border: 3px solid rgba(183, 139, 244, 0.5);
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
}

.btn-outline-primary,
.btn-outline-primary:focus {
    background-color: transparent;
    border-radius: 12px;
    border: 3px solid #825AF2;
    padding: 8px 20px;
    text-transform: capitalize;
    font-weight: bold;
    color: #825AF2;
    position: relative;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
    border: 3px solid #825AF2;
    background-color: #825AF2;
    color: #fff;
}

.btn-outline-primary:disabled {
    border: 3px solid rgba(130, 90, 242, 0.5);
    color: rgba(130, 90, 242, 0.5);
    cursor: not-allowed;
}

.btn-outline-primary .spinner-border {
    color: #825AF2 !important;
}

.card {
    border-radius: 14px;
    background-color: #230B5E;
    overflow: hidden;
    color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.card .card-body {
    padding: 20px;
}

.card .btn-success {
    background-color: #4caf50;
    border-color: #4caf50;
}

.card .btn-success:hover {
    background-color: #43a047;
}

.card .btn-danger {
    background-color: #e53935;
    border-color: #e53935;
}

.card .btn-danger:hover {
    background-color: #d32f2f;
}

.card .form-label {
    font-weight: bold;
}

.card .spinner-border {
    color: #ffffff;
}

.card .card-header {
    text-align: left;
    color: #ffffff;
    font-weight: bold;
}

.btn-group {
    width: 100%;
}

.btn-group .btn {
    background-color: transparent;
    border-bottom: 2px solid rgba(255,255,255,0.2);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    padding: 18px;
}

.btn-group .btn-primary {
    border-bottom: 2px solid #FFD700;
    color: #FFD700;
}

.form-control {
    background-color: #3a3555;
    color: #ffffff;
    border: 2px solid #5e4bff;
    border-radius:12px;
    padding: 10px 20px;
}

.form-control:focus, .form-control:disabled {
    background-color: #3a3555;
    color: #ffffff;
    border-color: #825aff;
    box-shadow: 0 0 5px #5e4bff;
}

.form-control::placeholder {
    color: #aaa;
}

.socials .btn {
    color: #FFBA35;
    border-color: #FFBA35;
}

.socials .btn:hover {
    background-color: #FFBA35;
    border-color: #fff;
    color: #fff;
}

.nav-item .dropdown-menu {
    background-color: #130048;
}

.nav-item .dropdown-menu a {
    color: #fff;
}

.nav-item .dropdown-menu a:hover {
    background-color: #5e4bff;
    color: #fff;
}

.no-border {
    border: none !important;
}

.progress {
    height: 20px;
    border-radius: 12px;
    background-color: #3a3555;
    overflow: hidden;
}

.progress-bar {
    background: linear-gradient(to right, #825AF2, #FFBA35);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
