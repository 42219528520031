#xp {
    position: absolute;
}

#referrer {
    position: absolute;
    right: 28px;
}

#topSection {
    margin-bottom: 20px;
}