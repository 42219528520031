/* General Card Styling */
.card {
    border: none;
    border-radius: 10px;
}

/* Start a New Coin Card */
.start-coin-card {
    background-color: #3B0078;
    color: #fff;
}

.start-coin-image {
    border-radius: 12px;
}

.start-coin-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
}

.start-coin-card .make-coin-button {
    position: absolute;
    bottom: 30px;
}

/* Top Tokens Card */
.top-tokens-card {
    background: linear-gradient(to bottom right, #E38D37, #C40052);
    color: #fff;
}

.top-tokens-card .list-group {
    border-radius: 0px 0px 12px 12px !important;
    padding: 0 16px 16px 16px;
}

.top-tokens-card .list-group-item {
    border: none;
    margin-top: 8px;
    border-radius: 12px;
    padding: 3px !important;
}

.top-tokens-header {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    border-radius: 12px 12px 0px 0px !important;
    color: #fff;
    border: none;
}

.top-token-item .inner {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 8px;
    color: #fff;
}

.token-image {
    width: 64px;
    height: 64px;
    border-radius: 8px;
}

.token-name {
    font-size: 1rem;
    font-weight: bold;
}

.token-mcap {
    font-size: 0.8rem;
    color: #ddd;
}

/* Latest Coins Card */
.latest-coins-card {
    background: linear-gradient(to bottom right, #B381F2, #DD33FF);
    color: #fff;
}

.latest-coins-card .inner {
    border-radius: 8px;
    background: rgba(99, 30, 186, 0.8);
    padding: 8px;
    color: #fff;
}

.latest-coins-header {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    border-radius: 12px 12px 0px 0px !important;
    color: #fff;
    border: none;
}

.latest-coins-card .list-group {
    border-radius: 0px 0px 12px 12px !important;
    padding: 0 16px 16px 16px;
}

.latest-coins-card .list-group-item {
    border: none;
    margin-top: 8px;
    border-radius: 12px;
    padding: 3px !important;
}

.coin-name {
    font-size: 1rem;
    font-weight: bold;
}

.coin-category {
    font-size: 0.8rem;
    color: #ddd;
}