.upload-container {
    position: relative;
    display: inline-block;
    width: 200px; /* Adjust width as needed */
    height: 200px; /* Adjust height as needed */
    border: 2px dashed #ddd;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    background-color: transparent;
    cursor: pointer;
  }
  
  .upload-container:hover {
    border-color: #007bff; /* Highlight on hover */
    background-color: transparent;
  }
  
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    display: block; /* Ensure it's displayed by default */
    transition: opacity 0.3s ease-in-out; /* Smooth transition for visibility */
  }
  
  .upload-container:hover .preview-image {
    opacity: 0.3; /* Make image semi-transparent on hover */
  }
  
  .upload-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: #007bff;
    font-size: 24px; /* Adjust spinner size */
    animation: spin 1s linear infinite; /* Make the spinner rotate */
  }
  
  .upload-input {
    opacity: 0; /* Hidden input */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 20; /* Ensure it's above the preview */
  }
  
  .upload-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    font-size: 16px;
    color: #888;
    text-align: center;
    transition: color 0.3s ease-in-out;
  }
  
  .upload-container:hover .upload-label {
    color: #007bff; /* Change label color on hover */
  }
  
  .upload-container.uploading .preview-image {
    opacity: 0.5; /* Dim the preview while uploading */
  }
  
  .upload-container.uploading .upload-label {
    display: none; /* Hide label while uploading */
  }
  
  /* Add keyframes for spinner rotation */
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  